import React from "react"

import DatasetDisplay from "../../components/DatasetDisplay"

// content specific imports
import ogImage from "../../images/data-points/global-2000-cybersecurity.png"

// tanstack table
import { createColumnHelper } from "@tanstack/react-table"
import { IconFileText } from "@tabler/icons"

let datasetJson = require("../../data/G2000-cybersecurity.json")

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor(row => row["name"], {
    id: "accountName",
    cell: info => (
      <div className="max-w-[100px] truncate">
        <a
          href={info.row.original["domain"]} rel="nofollow"
          target="_blank"
          className="text-blue underline truncate"
        >
          {info.getValue()}
        </a>
      </div>
    ),
    header: "Company",
  }),
  columnHelper.accessor(row => row["articles"], {
    id: "articles",
    cell: info => (
      <div>
        {info.getValue().map(article => (
          <div className="flex gap-3 items-center max-w-2xl">
            <div>
              <IconFileText size={16} className="text-medium-grey" />
            </div>
            <a
              href={article["url"]} rel="nofollow"
              className="max-w-lg truncate border-b border-b-medium-grey"
              target="_blank"
            >
              {article["title"]}
            </a>
            <div className="flex-1"> </div>
            <div className="text-dark-grey">{article["date"].slice(0, 10)}</div>
          </div>
        ))}
      </div>
    ),
    header: "Top Cybersecurity Updates, Last 3 months",
  }),
  columnHelper.accessor(row => row["score"], {
    id: "score",
    cell: info => (
      <div className="rounded-full shadow-sm w-4 h-4 border-light-grey">
        {info.getValue()}
      </div>
    ),
    header: "Score",
  }),
]

const dataContext = {
  title: "Global 2000 Cybersecurity monitor",
  description:
    "We looked at cyber security for Global 2000 companies: initiatives, attacks, types (IoT, OT, zero trust) and compiled interesting stories about recent events.",
  blurb:
    "We looked at cyber security for Global 2000 companies: initiatives, attacks, types (IoT, OT, zero trust) and compiled interesting stories about recent events.",
  ctaText: `Get the whole list of ${datasetJson.length} companies and sync it with your CRM.`,
  slug: "global-2000-cyber-security",
  freeLimit: 500,
  blogPost: (
    <>
      <p>
        What&#39;s the buzz in cyber security for Global 2000 companies? What
        initiatives have they taken and what cyber attacks have they faced in
        the past 3 months?
      </p>
      <p>
        Large companies have valuable information and assets stored on their
        computers, such as customer data, financial records, and confidential
        business plans. So, they invest in cybersecurity to protect their
        sensitive information and assets from cyber threats.
      </p>
      <p>
        <strong>Source</strong>: The company list comes from the 2021 Forbes
        Global 2000, which ranks the top 2000 public companies worldwide based
        on sales, profit, assets, and market value. Tactic gathered information
        from various sources, including news articles, annual reports, job
        postings, and interviews, to compile the dataset.
      </p>
      <p>
        <strong>Enrichment</strong>: I explored various dimensions to find
        interesting stories about cybersecurity, including types of
        cybersecurity like IoT security, OT security, and zero trust, as well as
        cyber attacks such as ransomware, man-in-the-middle attacks, and data
        breaches.
      </p>
      <p>
        <strong>Scoring:</strong> We evaluated each company&#39;s cybersecurity
        story based on the recency, source, and level of detail contained within
        it.{" "}
      </p>
      <p>
        The company&#39;s total score was calculated by aggregating the scores
        of its individual stories.{" "}
      </p>
      <p>
        The companies were then ranked according to their total scores, allowing
        us to determine which ones had the most interesting cybersecurity
        stories.
      </p>
      <p>
        <strong>Summaries</strong>: To provide a clear and concise overview of
        each company&#39;s cybersecurity story, we selected the three highest
        scoring and most informative stories and summarized them.{" "}
      </p>
      <p>
        The summaries and relevant links were organized in a table format to
        enable quick and easy access to this information.
      </p>
      <p>
        Need more info from this dataset, like if each company has a top
        executive for cyber security?{" "}
        <strong>
          <a href="#cta">contact us for a chat</a>!
        </strong>
      </p>
    </>
  ),
  columns: columns,
  published: "Feb 12, 2023",
}

const DataSet = ({ location }) => {
  return (
    <DatasetDisplay
      location={location}
      ogImage={ogImage}
      dataset={datasetJson}
      dataContext={dataContext}
      fullVersionOverride={true}
    />
  )
}

export default DataSet
